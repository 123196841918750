<template>
  <div>
    <b-overlay :show="loading">
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between statistic">
            <b-row v-if="statistic">
              <b-col
                v-for="(item, index) in statistic"
                :key="index"
                class="statistic__content"
              >
                <p class="m-0 statistic__label">{{ item.label }}</p>
                <p class="m-0 statistic__time">
                  {{ item.time > 0 ? convertTimestampToDate(item.time) : null }}
                </p>
                <p class="value">
                  <img
                    :src="item.image"
                    alt=""
                    v-if="item.image"
                    style="width: 30px; height: 30px"
                    class="mr-2"
                  />
                  <span class="value__main" :style="`color: ${item.color} `">
                    {{ item.value }}</span
                  >
                  <span class="value__unit">{{ item.unit }}</span>
                </p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-end">
          <multiselect
            class="mutiselect-rounded"
            v-model="selected"
            :options="options"
            :searchable="false"
            :show-labels="false"
            label="text"
            openDirection="bottom"
            :allowEmpty="false"
            @input="selectedOption"
          ></multiselect>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-card class="card" header-class="p-6">
            <template #header>
              <div
                class="d-flex justify-content-between align-items-center"
                style="width: 100%"
              >
                <h6 class="mb-0">Xu hướng đường huyết</h6>
              </div>
            </template>

            <div style="height: 340px; margin: auto">
              <BloodSugarTrendChart :dataChart="dataTrendChart" />
            </div>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card class="card" header-class="p-6">
            <template #header>
              <h6 class="mb-0 line-height-select">
                Tần suất phân bổ chỉ số đường huyết
              </h6>
            </template>
            <div style="height: 340px; margin: auto; overflow: hidden">
              <BloodSugarDistributionChart :dataChart="dataDistributionChart" />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="(item, idx) in progress" :key="idx" cols="4">
          <ProgressBar
            :name="`goal-${idx}`"
            :completed-target="item.completedTarget"
            :original-target="item.originalTarget"
            :title="item.title"
            :redirectPath="item.link ? item.link : null"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import round from 'lodash/round';
import Multiselect from 'vue-multiselect';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('userDashboard');
export default {
  name: 'OverView',
  components: {
    ProgressBar: () => import('../components/ProgressBar'),
    BloodSugarTrendChart: () => import('../components/BloodSugarTrendChart'),
    BloodSugarDistributionChart: () =>
      import('../components/BloodSugarDistributionChart'),
    Multiselect,
  },
  data() {
    return {
      statistic: [],
      options: [
        { text: 'Thống kê toàn lộ trình', value: 5 },
        { text: 'Thống kê 2 tuần gần nhất', value: 2 },
      ],
      selected: { text: 'Thống kê 2 tuần gần nhất', value: 2 },
      loading: false,
      dataTrendChart: {},
      dataDistributionChart: {},
      progress: [],
    };
  },
  computed: {
    ...mapState(['customerInfo']),
    patientId() {
      return this.$route.params.id;
    },
    searchParams() {
      return {
        periodFilterType: this.selected.value,
        patientId: this.patientId,
      };
    },
    isHasRoadmap() {
      return this.customerInfo?.ownPackage?.ownRoadmap || false;
    },
  },
  watch: {
    customerInfo: {
      handler(val) {
        if (!val) return;

        if (!val.ownPackage?.ownRoadmap) {
          this.options = [{ text: 'Thống kê 2 tuần gần nhất', value: 2 }];
        }
        this.loadData();
      },
      deep: true,
    },
  },
  async mounted() {
    if (!this.customerInfo) return;
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;
      const diabete = this.customerInfo.levelOfDiabetesRuleList?.find(
        (el) => el.selected,
      );
      await Promise.all([
        this.getBloodSugarTrend(diabete?.text),
        this.getInfomationGeneral(),
        this.getDistribution(diabete?.text),
        this.getProgress(),
      ]);
      this.loading = false;
    },
    async getInfomationGeneral() {
      try {
        let { data, meta, error } = await this.$api.get(
          `/UserDashboard/Summary/${this.patientId}`,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        const {
          bloodPressureIndex,
          emotionCard,
          glucoseIndex,
          weightCard,
          hbA1CIndex,
          energyCard,
          exercise,
        } = data;
        let bloodPressure = {
          label: 'Huyết áp',
          color: bloodPressureIndex.color,
          value: `${bloodPressureIndex.systolic}/${bloodPressureIndex.diastolic} `,
          time: bloodPressureIndex.createDateTime,
          unit: bloodPressureIndex.unit || 'mm/Hg',
        };
        let bloodSugar = {
          label: 'Đường huyết',
          color: glucoseIndex.color,
          value: `${glucoseIndex.index} `,
          time: glucoseIndex.createDateTime,
          unit: glucoseIndex.unit || 'mg/DL',
        };
        let hbA1C = {
          label: 'HbA1c',
          color: hbA1CIndex.color,
          value: `${hbA1CIndex.index} `,
          time: hbA1CIndex.createDateTime,
          unit: '%',
        };
        let emotion = {
          label: 'Cảm xúc',
          color: emotionCard.color || '#000',
          image: emotionCard?.details?.[0]?.icon?.url,
          value: emotionCard?.details?.[0].text,
          time: emotionCard.emotionDateTime,
        };
        let food = {
          label: 'Dinh dưỡng',
          color: energyCard.color,
          value: `${round(energyCard.consumedEnergy)} `,
          time: energyCard.consumedEnergyDateTime,
          unit: energyCard.unit || 'kcal',
        };
        let weight = {
          label: 'Cân nặng',
          color: weightCard.color,
          value: `${weightCard.weight} `,
          time: weightCard.weightDateTime,
          unit: weightCard.unit || 'kg',
        };
        let pulseRate = {
          label: 'Nhịp tim',
          color: '#000',
          value: `${bloodPressureIndex.pulseRate}`,
          time: bloodPressureIndex.createDateTime,
          unit: bloodPressureIndex.unit || 'lần/phút',
        };
        let exerciseIndex = {
          label: 'Vận động',
          color: exercise.color,
          value: `${exercise.index} `,
          time: exercise.createDateTime,
          unit: exercise.unit || 'kcal',
        };
        this.statistic = [
          bloodSugar,
          hbA1C,
          bloodPressure,
          weight,
          exerciseIndex,
          food,
          pulseRate,
          emotion,
        ];
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    async getBloodSugarTrend(diabeteName) {
      let payloadParam = this.searchParams;
      if(diabeteName === "Đái tháo đường thai kỳ") {
        payloadParam['thresholdType'] = 1;
      }
      try {
        const { data, meta, error } = await this.$api.get(
          '/UserDashboard/Trend',
          {
            params: payloadParam,
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.dataTrendChart = data;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    async getDistribution(diabeteName) {
      let payloadParam = this.searchParams;
      if(diabeteName === "Đái tháo đường thai kỳ") {
        payloadParam['thresholdType'] = 1;
      }
      try {
        const { data, meta, error } = await this.$api.get(
          '/UserDashboard/Distribution',
          {
            params: payloadParam,
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.dataDistributionChart = data;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    async getProgress() {
      try {
        const params = {
          type: this.selected.value === 2 ? 1 : 0,
          patientId: this.patientId,
        };

        const { data, meta, error } = await this.$api.get(
          '/UserDashboard/Get-Progress',
          {
            params,
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        const target = {
          title: 'Mục tiêu',
          completedTarget:
            this.selected.value === 2
              ? data.target.inTimeCompleted
              : data.target.allTimeCompleted,
          originalTarget:
            this.selected.value === 2
              ? data.target.inTime
              : data.target.allTime,
        };

        const coach11 = {
          title: 'Coach 1-1',
          completedTarget:
            this.selected.value === 2
              ? data.coach11.inTimeCompleted
              : data.coach11.allTimeCompleted,
          originalTarget:
            this.selected.value === 2
              ? data.coach11.inTime
              : data.coach11.allTime,
        };
        const coach1N = {
          title: 'Coach 1-N',
          completedTarget:
            this.selected.value === 2
              ? data.coach1N.inTimeCompleted
              : data.coach1N.allTimeCompleted,
          originalTarget:
            this.selected.value === 2
              ? data.coach1N.inTime
              : data.coach1N.allTime,
        };

        const lesson = {
          title: 'Bài học',
          link: `/user_dashboard/${this.patientId}/lessons`,
          completedTarget:
            this.selected.value === 2
              ? data.lesson.inTimeCompleted
              : data.lesson.allTimeCompleted,
          originalTarget:
            this.selected.value === 2
              ? data.lesson.inTime
              : data.lesson.allTime,
        };
        const exerciseMovement = {
          title: 'Vận động',
          link: `/user_dashboard/${this.patientId}/schedule`,
          completedTarget:
            this.selected.value === 2
              ? data.exerciseMovement.inTimeCompleted
              : data.exerciseMovement.allTimeCompleted,
          originalTarget:
            this.selected.value === 2
              ? data.exerciseMovement.inTime
              : data.exerciseMovement.allTime,
        };

        if (this.isHasRoadmap) {
          return (this.progress = [
            target,
            coach11,
            coach1N,
            lesson,
            exerciseMovement,
          ]);
        }

        return (this.progress = [target, coach11, coach1N]);
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    selectedOption() {
      this.loadData();
    },
  },
};
</script>
<style lang="scss" scoped>
.value__main {
  font-weight: bold;
  font-size: 13px;
  line-height: 132%;
}

.value__unit {
  font-size: 12px;
  line-height: 16px;
  color: #515356;
}

.statistic__label {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  color: #172823;
}

.statistic__time {
  font-size: 10px;
  line-height: 14px;
  color: #b5b5c3;
}

.statistic__content {
  border-right: 1px solid #ebedf3;

  &:last-child {
    border-right: unset;
  }
}

.chart__container {
  height: 340px;
  margin: 24px 0;
  column-gap: 18px;
}

.chart__item {
  width: 50%;
  height: 340px;
  border: 1px solid #ebedf3;
  border-radius: 16px;
  padding: 16px;
}

.chart__title {
  font-weight: bold;
  font-size: 16px;
  color: #515356;
}
</style>

<style lang="scss">
.mutiselect-rounded {
  width: 240px;
  text-align: center;
  color: #008479;
}
.mutiselect-rounded {
  .multiselect__tags {
    border-radius: 50px !important;
    border-color: #008479;
  }
}
</style>
